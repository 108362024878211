import axios from '@axios'
import common from '@/libs/common'

export default function generalDataService() {
  const {
    throwError,
    throwSuccess,
    // toastSuccess,
    // toastError,
  } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  // ===================================================================|
  //  RequirementQualityTypes---------------------------------------------------------------------------------|
  // ===================================================================|

  const fetchRequirementQualityTypes = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/RequerimientoCalidadTipo`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRequirementQualityType = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/RequerimientoCalidadTipo/${data.requerimientoCalidadTipoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRequirementQualityType = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/RequerimientoCalidadTipo/${data.requerimientoCalidadTipoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createRequirementQualityType = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/AccionesMejora/RequerimientoCalidadTipo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteRequirementQualityType = (data, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/RequerimientoCalidadTipo/${data}`)
      .then(response => {
        callback(response.data)
        throwSuccess(response)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  RequirementDocumentTypes---------------------------------------------------------------------------------|
  // ===================================================================|

  const fetchRequirementDocumentTypes = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/RequerimientoDocumentoTipo`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRequirementDocumentType = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/RequerimientoDocumentoTipo/${data.requerimientoDocumentoTipoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRequirementDocumentType = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/RequerimientoDocumentoTipo/${data.requerimientoDocumentoTipoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createRequirementDocumentType = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/AccionesMejora/RequerimientoDocumentoTipo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteRequirementDocumentType = (data, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/RequerimientoDocumentoTipo/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Requirement-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchRequirements = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRequirement = (data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${data.requerimientoDocumentoTipoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRequirement = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/${data.requerimientoDocumentoTipoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createRequirement = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteRequirement = (data, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  return {
    //  RequirementQualityType
    fetchRequirementQualityTypes,
    fetchRequirementQualityType,
    updateRequirementQualityType,
    createRequirementQualityType,
    deleteRequirementQualityType,
    // RequirementDocumentType
    fetchRequirementDocumentTypes,
    fetchRequirementDocumentType,
    updateRequirementDocumentType,
    createRequirementDocumentType,
    deleteRequirementDocumentType,
    //  Requirement
    fetchRequirements,
    fetchRequirement,
    updateRequirement,
    createRequirement,
    deleteRequirement,
  }
}
